import Glide, { Controls, Breakpoints, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'
import 'fslightbox';

document.addEventListener('DOMContentLoaded', () => {
	const sliders = document.querySelectorAll('.glide')

	const sliderExtensions = {
		Controls,
		Breakpoints,
		Swipe,
		Autoplay
	}

	sliders.forEach((slider) => {
		const sliderOptions = {
			type: 'carousel',
			perTouch: slider.dataset.perView,
			perView: slider.dataset.perView,
			autoplay: Number(slider.dataset.autoplay) || 0,
			breakpoints: {
				768: {
					perTouch: 1,
					perView: 1
				}
			}
		}
		new Glide(slider, sliderOptions).mount(sliderExtensions)
	})
})
